import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

// import Search from "antd/es/input/Search";
import { useLocation, useNavigate } from "react-router-dom";

import { IHistoryAllowance } from "apis/response";

import { DatePicker, Pagination, PaginationProps, Table } from "antd";
import { paramsFilter } from "utils/ParamsFilter";

import { ColumnsType } from "antd/es/table";
import Apis from "apis/remotes";
import { formatDate } from "utils/utilDate";

const { RangePicker } = DatePicker;
function MissionHistoryList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const order = params.get("order");
  const page = params.get("page");
  const startTime = params.get("startTime");
  const endTime = params.get("endTime");

  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<IHistoryAllowance[]>([]);
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/pin-money-management/mission-history-list${paramsFilter({
        order,
        startTime,
        endTime,
        page,
      })}`
    );
  };

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    // console.log(dateStrings);
    // console.log(dates);
    if (dates) {
      setCurrentPage(1);
      navigate(
        `/pin-money-management/mission-history-list${paramsFilter({
          order,
          page: 1,
          startTime: dateStrings[0],
          endTime: dateStrings[1],
        })}`
      );
    } else {
      setCurrentPage(1);
      navigate(
        `/pin-money-management/mission-history-list${paramsFilter({
          order,
          page: 1,
        })}`
      );
    }
  };

  const tableColumn: ColumnsType<IHistoryAllowance> = [
    {
      title: "순번",
      dataIndex: "allowanceHistoryId",
      key: "allowanceHistoryId",
      align: "center",
    },
    {
      title: "거래종류",
      dataIndex: "allowanceType",
      key: "allowanceType",
      align: "center",
    },
    {
      title: "이메일",
      dataIndex: "email",
      key: "email",
      align: "center",
    },
    {
      title: "이름",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "보낸금액",
      dataIndex: "amount",
      key: "amount",
      align: "right",
      render: (amount: number) => amount.toLocaleString(),
    },
    {
      title: "진행 날짜",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text) => formatDate(text),
    },
  ];

  useEffect(() => {
    Apis.getHistoryAllowance({ page: currentPage, perPage: 10, startTime, endTime })
      .then((resolve) => {
        setData(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage, startTime, endTime]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container py={3}>
          <Grid item xs={6}>
            <RangePicker onChange={handleDateRangeChange} style={{ marginRight: 10 }} />
          </Grid>
          <Grid item xs={6}>
            {/* <Search
              maxLength={30}
              placeholder="회원의 이름 휴대전화번호를 검색하세요"
              onSearch={(text) => handleSearch(text)}
            /> */}
          </Grid>
        </Grid>

        <Card>
          <Table
            pagination={false}
            dataSource={data}
            rowKey={(record) => record.allowanceHistoryId}
            scroll={{ x: "max-content" }}
            columns={tableColumn}
            // style={{ cursor: "pointer" }}
            // onRow={(record, _) => {
            //   return {
            //     onClick: () => {
            //       navigate(`/pin-money-management/mission-history-details/${record.transactionId}`);
            //     },
            //   };
            // }}
            footer={() => (
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default MissionHistoryList;
