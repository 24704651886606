import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

import Search from "antd/es/input/Search";
import { useLocation, useNavigate } from "react-router-dom";

import { DatePicker, Pagination, PaginationProps, Table } from "antd";
import { paramsFilter } from "utils/ParamsFilter";

import { ColumnsType } from "antd/es/table";
import { formatDate } from "utils/utilDate";
import Apis from "apis/remotes";
import { IHistoryPayment, PaymentStatusType } from "apis/response";
import MDBadge from "components/MDBadge";

const { RangePicker } = DatePicker;
function ChargingHistoryList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const search = params.get("search");
  const sort = params.get("sort");
  const order = params.get("order");
  const page = params.get("page");
  const startTime = params.get("startTime");
  const endTime = params.get("endTime");

  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [data, setData] = useState<IHistoryPayment[]>([]);
  const [total, setTotal] = useState(0);
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/pin-money-management/charging-history-list${paramsFilter({
        sort,
        order,
        search,
        page,
        startTime,
        endTime,
      })}`
    );
  };
  const handleSearch = (text: string) => {
    setCurrentPage(1);
    navigate(
      `/pin-money-management/charging-history-list${paramsFilter({
        sort,
        order,
        search: text,
        page: 1,
        startTime,
        endTime,
      })}`
    );
  };
  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    if (dates) {
      setCurrentPage(1);
      navigate(
        `/pin-money-management/charging-history-list${paramsFilter({
          order,
          page: 1,
          startTime: dateStrings[0],
          endTime: dateStrings[1],
        })}`
      );
    } else {
      setCurrentPage(1);
      navigate(
        `/pin-money-management/charging-history-list${paramsFilter({
          order,
          page: 1,
        })}`
      );
    }
  };

  const tableColumn: ColumnsType<IHistoryPayment> = [
    // {
    //   title: "순번",
    //   dataIndex: "paymentId",
    //   key: "paymentId",
    //   align: "center",
    // },
    {
      title: "이메일",
      dataIndex: "email",
      key: "email",
      align: "center",
    },
    {
      title: "이름",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    // {
    //   title: "프로필 ID",
    //   dataIndex: "profileId",
    //   key: "profileId",
    //   align: "center",
    // },
    {
      title: "결제 상태",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      align: "center",
      render: (paymentStatus: PaymentStatusType) => {
        switch (paymentStatus) {
          case "pending":
            return <MDBadge badgeContent="결제 진행 중" color="light" size="lg" />;
          case "paid":
            return <MDBadge badgeContent="결제 완료" color="success" size="lg" />;
          case "cancel":
            return <MDBadge badgeContent="취소" color="error" size="lg" />;
          default:
            return <MDBadge badgeContent="알 수 없음" color="default" size="lg" />;
        }
      },
    },
    {
      title: "결제금액",
      dataIndex: "amount",
      key: "amount",
      align: "right",
      render: (amount: number) => amount.toLocaleString(),
    },
    {
      title: "충전 포인트",
      dataIndex: "realAmount",
      key: "realAmount",
      align: "right",
      render: (realAmount: number) => realAmount.toLocaleString(),
    },
    {
      title: "충전 수수료",
      dataIndex: "paymentFee",
      key: "paymentFee",
      align: "right",
      render: (paymentFee: number) => paymentFee.toLocaleString(),
    },
    {
      title: "충전 날짜",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text) => formatDate(text),
    },
  ];

  useEffect(() => {
    Apis.getHistoryPayment({
      page: currentPage,
      perPage: 10,
      search,
      sort,
      order,
      startTime,
      endTime,
    })
      .then((resolve) => {
        setData(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage, search, sort, order, startTime, endTime]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container py={3}>
          <Grid item xs={6}>
            <RangePicker onChange={handleDateRangeChange} style={{ marginRight: 10 }} />
          </Grid>
          <Grid item xs={6}>
            {/* <Search
              maxLength={30}
              placeholder="회원의 이름 휴대전화번호를 검색하세요"
              onSearch={(text) => handleSearch(text)}
            /> */}
          </Grid>
        </Grid>
        <Card>
          <Table
            pagination={false}
            dataSource={data}
            rowKey={(record) => record.paymentId}
            scroll={{ x: "max-content" }}
            columns={tableColumn}
            // style={{ cursor: "pointer" }}
            // onRow={(record, _) => {
            //   return {
            //     onClick: () => {
            //       navigate(`/pin-money-management/charging-history-details/${record.paymentId}`);
            //     },
            //   };
            // }}
            footer={() => (
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default ChargingHistoryList;
