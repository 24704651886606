import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DatePicker, Pagination, PaginationProps, Table } from "antd";
import { paramsFilter } from "utils/ParamsFilter";
import { ColumnsType } from "antd/es/table";
import MDButton from "components/MDButton";
// import { TableRowSelection } from "antd/es/table/interface";
import Apis from "apis/remotes";
import { SettlementStatusType, SettlementType, ISettlement } from "apis/response";
import MDBadge from "components/MDBadge";
import { formatDate } from "utils/utilDate";

const { RangePicker } = DatePicker;

function SettlementHistoryList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const search = params.get("search");
  const sort = params.get("sort");
  const order = params.get("order");
  const page = params.get("page");
  const startTime = params.get("startTime");
  const endTime = params.get("endTime");

  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [data, setData] = useState<ISettlement[]>([]);
  const [total, setTotal] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]); // Explicitly define the type as number[]

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/pin-money-management/settlement-history-list${paramsFilter({
        sort,
        order,
        search,
        page,
        startTime,
        endTime,
      })}`
    );
  };

  const handleSearch = (text: string) => {
    setCurrentPage(1);
    navigate(
      `/pin-money-management/settlement-history-list${paramsFilter({
        sort,
        order,
        search: text,
        page: 1,
        startTime,
        endTime,
      })}`
    );
  };

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    if (dates) {
      setCurrentPage(1);
      navigate(
        `/pin-money-management/settlement-history-list${paramsFilter({
          order,
          page: 1,
          startTime: dateStrings[0],
          endTime: dateStrings[1],
        })}`
      );
    } else {
      setCurrentPage(1);
      navigate(
        `/pin-money-management/settlement-history-list${paramsFilter({
          order,
          page: 1,
        })}`
      );
    }
  };

  /** 입금완료 api */
  const handleSetCompleted = (allowanceSettlementId: number) => {
    console.log(allowanceSettlementId);

    Apis.putSettlement(allowanceSettlementId)
      .then((resolve) => {
        // 성공적으로 입금 완료 후 해당 데이터의 상태를 complete로 변경
        setData((prevData) => {
          return prevData.map((item) => {
            if (item.allowanceSettlementId === allowanceSettlementId) {
              // settlementStatus를 "complete"로 변경
              return {
                ...item,
                settlementStatus: "complete",
              };
            }
            return item;
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const rowSelection: TableRowSelection<ISettlement> = {
  //   selectedRowKeys,
  //   onChange: (selectedKeys) => setSelectedRowKeys(selectedKeys as number[]), // Type assertion
  //   getCheckboxProps: (record: ISettlement) => ({
  //     disabled: record.settlementType !== "manual", // settlementType이 "manual"이 아닌 경우 체크박스 비활성화
  //   }),
  // };

  const tableColumn: ColumnsType<ISettlement> = [
    {
      title: "순번",
      dataIndex: "allowanceSettlementId",
      key: "allowanceSettlementId",
      align: "center",
    },
    {
      title: "거래상태",
      dataIndex: "settlementStatus",
      key: "settlementStatus",
      align: "center",
      render: (settlementStatus: SettlementStatusType) => {
        switch (settlementStatus) {
          case "pending":
            return <MDBadge badgeContent="입금 진행 중" color="light" size="lg" />;
          case "complete":
            return <MDBadge badgeContent="입금 완료" color="success" size="lg" />;
          default:
            return <MDBadge badgeContent="알 수 없음" color="default" size="lg" />;
        }
      },
    },
    {
      title: "이름",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "정산신청금액",
      dataIndex: "settlementAmount",
      key: "settlementAmount",
      align: "right",
      render: (settlementAmount: number) => settlementAmount.toLocaleString(),
    },
    {
      title: "수수료",
      dataIndex: "settlementFee",
      key: "settlementFee",
      align: "right",
      render: (settlementFee: number) => settlementFee.toLocaleString(),
    },
    {
      title: "실정산금액",
      dataIndex: "realSettlementAmount",
      key: "realSettlementAmount",
      align: "right",
      render: (realSettlementAmount: number) => realSettlementAmount.toLocaleString(),
    },
    {
      title: "정산종류",
      dataIndex: "settlementType",
      key: "settlementType",
      align: "center",
      render: (settlementType: SettlementType) => {
        switch (settlementType) {
          case "auto":
            return "자동 입금";
          case "manual":
            return "수동 입금";
          default:
            return <MDBadge badgeContent="알 수 없음" color="default" size="lg" />;
        }
      },
    },
    {
      title: "은행명",
      dataIndex: "bankName",
      key: "bankName",
      align: "center",
    },
    {
      title: "계좌번호",
      dataIndex: "bankAccount",
      key: "bankAccount",
      align: "center",
    },
    {
      title: "정산 요청 날짜",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text) => formatDate(text),
    },
    {
      title: "개별 입금완료",
      dataIndex: "settlementType",
      key: "settlementType",
      render: (settlementType: SettlementType, record) => {
        switch (settlementType) {
          case "manual":
            return (
              <MDButton
                size="small"
                color={record.settlementStatus === "complete" ? "dark" : "info"}
                disabled={record.settlementStatus === "complete"}
                onClick={() => handleSetCompleted(record.allowanceSettlementId)}
              >
                입금완료
              </MDButton>
            );
          case "auto":
            return "자동 입금";
          default:
            return null;
        }
      },
      align: "center",
    },
  ];

  useEffect(() => {
    Apis.getSettlement({
      page: currentPage,
      perPage: 10,
      startTime,
      endTime,
    })
      .then((resolve) => {
        setData(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage, startTime, endTime]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container py={3}>
          <Grid item xs={6}>
            <RangePicker onChange={handleDateRangeChange} style={{ marginRight: 10 }} />
          </Grid>
          <Grid item xs={6}>
            {/* <Search
              maxLength={30}
              placeholder="회원의 이름 휴대전화번호를 검색하세요"
              onSearch={(text) => handleSearch(text)}
            /> */}
          </Grid>
        </Grid>

        <Card>
          <Table
            // rowSelection={rowSelection}
            pagination={false}
            dataSource={data}
            rowKey={(record) => record.allowanceSettlementId}
            scroll={{ x: "max-content" }}
            columns={tableColumn}
            // style={{ cursor: "pointer" }}
            // onRow={(record, _) => {
            //   return {
            //     onClick: () => {
            //       navigate(
            //         `/pin-money-management/settlement-history-details/${record.transactionId}`
            //       );
            //     },
            //   };
            // }}
            footer={() => (
              <div
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                {/* <MDButton color="primary" onClick={() => handleSetCompleted()}>
                  공통 입금완료
                </MDButton> */}
                <Pagination
                  current={currentPage}
                  onChange={handleOnChangePage}
                  pageSize={10}
                  total={total}
                  showSizeChanger={false}
                />
              </div>
            )}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default SettlementHistoryList;
